import React from 'react'
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import Services from './Services';
import Statistic from './Statistic';
import Eligibilitycriteria from './Eligibilitycriteria';
import Review from './Review';
function Content() {
  
  return (
    <div className='main'>
    <section className="hero">
    <h2>Instant Legal. <strong>Wedding.</strong></h2>
    <p>Your trusted partner in legal matters.</p>
    <button ><Link to="/bookappoinment" className='herobutton'>Book Appoinments</Link></button>
  </section>
<Eligibilitycriteria/>
<Services/>
<Statistic maxNumber={986} companyashtablish={2015} partners={43} clients={1500} />
<Review/>
    </div>
  )
}

export default Content;
