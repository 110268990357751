import React from 'react'
import image from '../img/6.png'
import Strategy from './Strategy';
import FAQ from './FAQ';
function Courtmarriage() {

  return (
   <>
   <div className="family-law-service">
      <section className="intro-section">
        <br />
        <br />
        <h1>Couple Court Marriage</h1>
        <p>
        Court Marriage in India is a simple and legally recognized process where two individuals can marry without the need for a religious ceremony. This type of marriage is conducted by a marriage officer and is available to all Indian citizens, regardless of religion, caste, or creed, under the Special Marriage Act, 1954. Here’s everything you need to know about court marriage:
        </p>
        <img src={image} alt="Family" className="intro-image" />
        <p>
        Eligibility Criteria for Court Marriage
         </p>
       <li>Bride must be at least 18 years old.</li>
       <li>Groom must be at least 21 years old.</li>
       <li>Mental Capacity: Both parties must be capable of giving consent and should not be mentally unsound.</li>
       <li>Both parties should be unmarried. If previously married, they must provide proof of divorce or death of their former spouse</li>
      </section>
<Strategy/>
<FAQ/>
    </div>
   </>
  )
}

export default Courtmarriage
