import React from 'react'
import image from '../img/5.jpeg'
import Strategy from './Strategy'
import FAQ from './FAQ'
function Policeprotectioncourtmarriage() {
 
  return (
    <div>
      <div className="family-law-service">
        <section className="intro-section">
          <br />
          <br />
          <h1>Couple Court Marriage with Police Protection</h1>
          <p>
            Understanding the Need for Police Protection in Court Marriages
          </p>
          <p><li>Situations Requiring Protection: Many couples face opposition due to inter-caste or inter-religious marriages, family pressure, or societal norms. Police protection ensures the couple's safety from potential threats or harassment. </li></p>
          <p><li>Legal Rights: The Supreme Court of India has ruled that consenting adults have the right to marry without interference. Police protection is a legal safeguard for couples who feel threatened. </li></p>
          <img src={image} alt="Family" className="intro-image" />
          <p>
            Eligibility and Applicable Law for Court Marriage
          </p>
          <li>Eligibility:
            Both parties must be adults (21 years for men and 18 years for women).
            Both parties must provide voluntary consent to the marriage.</li>
          <p>
            Police Protection Eligibility: Police protection is available for couples who have completed the legal marriage process but feel threatened. Couples can apply for police protection if they have received threats or anticipate harm from family members or the community.
          </p>
        </section>

        <Strategy/>
        <FAQ/>

       
      </div>
    </div>
  )
}

export default Policeprotectioncourtmarriage
