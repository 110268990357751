import React from 'react'
import Slider from 'react-slick';
function Carousal() {
  const settings = {
    dots: true,            // Show dots for navigation
    infinite: true,        // Loop slides
    speed: 500,            // Transition speed
    slidesToShow: 1,       // Show one slide at a time
    slidesToScroll: 1,     // Scroll one slide at a time
    autoplay: true,        // Enable autoplay
    autoplaySpeed: 3000,   // Set autoplay speed (in ms)
  };
  const cardData = [
    { id: 1, title: "Court Marriage", description: "Court Marriage in India is a simple and legally recognized process where two individuals can marry without the need for a religious ceremony." },
    { id: 2, title: "Marriage Registration", description: "Court Marriage in India is a simple and legally recognized process where two individuals can marry without the need for a religious ceremony. " },
    { id: 3, title: "Couple Go Abroad on Visa by Spouse Visa", description: "Spouse Visa Eligibility Visa Options for Married Couples Planning to Go Abroad" },
    { id: 4, title: "Love Marriage", description: "Love Marriage is a marriage in which the couple decides to marry based on love, affection, and personal choice, rather than traditional arranged marriage norms." },
    { id: 5, title: "Court Marriage with police protection", description: "Understanding the Need for Police Protection in Court Marriages" },
    { id: 6, title: "Marriage Registration for government docs", description: "Marriage Registration is a legal acknowledgment of marriage by the government, granting the couple a marriage certificate." },
    { id: 7, title: "NRI Marriage under special marriage act", description: "Indian Citizens residing abroad (Non-Resident Indians) may also marry under the Special Marriage Act, 1954, or choose a court marriage within India." },
    { id: 8, title: "Hindu Marriage", description: "The Hindu Marriage Act, 1955, outlines the legal requirements for marriage between Hindus in India." },
    { id: 9, title: "Special Marriage act", description: "The Special Marriage Act, 1954 is a law enacted in India that allows individuals of different religions, castes, or communities to marry without having to convert or perform any religious ceremonies." },
  ];

  // Function to split cards into groups of two
  const groupedCards = [];
  for (let i = 0; i < cardData.length; i += 3) {
    groupedCards.push(cardData.slice(i, i + 3));
  }
  return (
    <div className='carousal' >
      <Slider {...settings}>
        {groupedCards.map((group, index) => (
          <div key={index} className="slide">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {group.map((card) => (
                <div key={card.id} className="card" >
                  <h3>{card.title}</h3>
                  <p>{card.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousal
