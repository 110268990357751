import React from 'react'

function Strategy() {
  return (
    <section className="strategy-section">
          <h2>Our Strategy</h2>
          <p>
            We’re proud that our law firm offers top-notch legal services for a nation wide affordable pricing! With us, you'll never feel like the lawyers are just robbers in suits besides, we win 98% of all cases. So with us, your chances of winning are as high as they possibly can be!
          </p>
          <div className="strategy-cards">
            <div className="strategy-card">
              <h3>01</h3>
              <h4>Document Preparation</h4>
              <p>Collect and verify all required documents in advance (e.g., ID proof, address proof, passport-sized photos). This helps avoid delays and ensures your application is processed smoothly.</p>
            </div>
            <div className="strategy-card">
              <h3>02</h3>
              <h4>Witness Readiness</h4>
              <p>Arrange reliable witnesses who are available and understand the requirements. Witnesses must be present on the day of marriage registration, so confirming their availability is essential.</p>
            </div>
            <div className="strategy-card">
              <h3>03</h3>
              <h4>Plan for Objection Period</h4>
              <p> Be aware of the 30-day waiting period after submitting your notice, allowing for any objections. Ensure that your personal and address details are correctly listed to prevent any procedural issues.</p>
            </div>
          </div>
        </section>

  )
}

export default Strategy
