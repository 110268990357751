import React from 'react'
import image from '../img/9.jpeg'
import Strategy from './Strategy';
import FAQ from './FAQ';

function Lovemarriage() {
 
  return (
    <div>
      <div className="family-law-service">
        <section className="intro-section">
          <br />
          <br />
          <h1>Love Marriage and Legal Aspects in India</h1>
          <p>
            Love Marriage is a marriage in which the couple decides to marry based on love, affection, and personal choice, rather than traditional arranged marriage norms. Love marriages may sometimes face societal or familial opposition, but legally, both adults have the right to marry by their own consent under the Indian law.
          </p>
          <h2>Legal Procedure for Court Marriage in India</h2>
          <p>
            If a couple faces challenges or opposition, they may choose to proceed with a court marriage under the Special Marriage Act, 1954, which allows any two individuals, irrespective of caste, religion, or nationality, to marry legally in a court.
          </p>
          <li>Both partners need to give a written notice of their intended marriage to the marriage officer in the jurisdiction where at least one of them has resided for 30 days prior to the notice.
            This notice is then displayed publicly at the office for 30 days.</li>
          <img src={image} alt="Family" className="intro-image" />
          <h2>Documents Required for Court Marriage</h2>
          <p>
            Age Proof: Birth certificate, school certificate, or passport for both parties.
            Address proof (Aadhaar card, passport, voter ID, etc.)
            Recent passport-sized photographs.
            Witnesses’ , Three witnesses with valid ID proofs.
          </p>
        </section>
        
       <Strategy/>
       <FAQ/>
        
      </div>
    </div>
  )
}

export default Lovemarriage
