import React,{useState,useEffect} from 'react'
import axios from 'axios';
function Model() {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [formData, setFormData] = useState({ name: '', phone: '', query: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        if (isFormSubmitted) {
            setIsModalOpen(false);
        }
    }, [isFormSubmitted]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const apiUrl ="https://instant-legal-wedding-backend.vercel.app";
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.name && formData.phone && formData.query) {
            try {
                await axios.post(`${apiUrl}/api/form`, formData);
                setIsFormSubmitted(true);
                alert("data is submitted")
                console.log("data is successfully submitted",formData)
            } catch (error) {
                console.error('Error submitting form data:', error);
            }
        } else {
            alert("Please fill in all fields.");
        }
    };

    if (!isModalOpen) return null;
  return (
    
      <div className="modalStyle">
           
            <form onSubmit={handleSubmit}>
            <h2>Enter Your Details</h2>
                <div className='model'>
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='model'>
                    <label>Phone</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='model'>
                    <label>Query</label>
                    <textarea
                        name="query"
                        value={formData.query}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
   
  )
}
export default Model
