import React from 'react'
import toplogo from "../img/flower-bottom.png"
import Navbar from './Navbar'
function Tophead() {
  return (
   <>
    <div className='tophead'>
        <div className='headertop'>
        <img src={toplogo} alt="logo" />
        <p>Get 20% off this month when you try our services!</p>
        <img src={toplogo} alt="logo" />
        </div>
        <Navbar/>
    </div>

   
    </>
  )
}

export default Tophead
