import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from "../img/logo.png";
import { Link } from 'react-router-dom';
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  

  return (
    <>
      <header className="navbar">
        <nav>
          <ul>
            <div className='logo'>
              <img src={backgroundImage} alt="logo" />
            </div>
            <Link to="/" className='navlink' >HOME</Link>
            <Link to="/aboutus" className='navlink'>ABOUT</Link>
            <Link to="/gallery" className='navlink'>GALLERY</Link>
            <Link to="/services" className='navlink'>SERVICES</Link>
            <Link to="/workingarea" className='navlink'>WORKING AREAS</Link>
            <Link to="/contact" className='navlink'>CONTACT</Link>
           
            <div >
              <div >
                {/* Hamburger Icon */}
                <div className="hamburger" onClick={toggleSidebar}>
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </div>

                {/* Sidebar */}
                <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                  <button className="close-btn" onClick={toggleSidebar}>&times;</button>
                  <ul className="menu">
                 
                    <li><Link to="/courtmarriage">Court Marriage</Link></li>
                    <li><Link to="/lovemarriage">Love Marriage</Link></li>
                    <li><Link to="/hindumarriage">Hindu Marriage</Link></li>
                    <li><Link to="/specialmarriage">Special Marriage Act</Link></li>
                    <li><Link to="/NRI">NRI Marriage under Special Marriage Act</Link></li>
                    <li><Link to="/marriagebygovt">Marriage Registration for Government Docs</Link></li>
                    <li><Link to="/marriagebyprotection">Court Marriage with Police Protection</Link></li>
                    <li><Link to="/spousevisa">Couple Go Abroad on Visa by Spouse Visa</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Navbar
