import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
function Map() {
  return (
    <div className='map'>
     <h1>Location</h1>
      <MapContainer center={[28.73, 77.11]} zoom={8} style={{ height: "50vh", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        title="newdelhi"
      />
      <Marker position={[28.73, 77.11]} Label={"A"}>
        <Popup>
         LegalBliss<br />Delhi,Rohini.
        </Popup>
      </Marker>
    </MapContainer>
    </div>
  )
}

export default Map
