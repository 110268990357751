import React, { useState } from 'react'
import axios from 'axios';
import Notification from './Notification';
function Contact() {
  const [Data, setData] = useState({ name: '', phone: '', msg: '', email: '' });
  const [notification, setNotification] = useState({ message: '', type: '' });
  const handleChange = (e) => {
    setData({
      ...Data,
      [e.target.name]: e.target.value,
    });
  };
  const apiUrl = "https://instant-legal-wedding-backend.vercel.app";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Data.name && Data.phone && Data.msg && Data.email) {
      try {
        await axios.post(`${apiUrl}/api/data`, Data);
        setNotification({ message: 'Form submitted successfully!', type: 'success' });
        console.log("data is successfully submitted", Data)
      } catch (error) {
        setNotification({ message: 'Error submitting form data. Please try again.', type: 'error' });
      }
    } else {
      setNotification({ message: 'Please fill in all fields.', type: 'warning' });
    }
  };

  const closeNotification = () => {
    setNotification({ message: '', type: '' });
};

  return (
    <>
    
    
    <section className="contact-section">
      <h1>Contact Us</h1>
      <br />
      <p>Feel Free to Reach Us</p>
      <h1>Better yet , see us in person!</h1>
      <br />
      <p>We love our customers , so feel free to visit during normal business hours</p>
      <br />
      <form className="contact-form" onSubmit={handleSubmit} >
        <input type="text" placeholder="Your Name" required name="name"
          value={Data.name}
          onChange={handleChange} />
        <input type="email" placeholder="Email" required name="email"
          value={Data.email}
          onChange={handleChange} />
        <input type="tel" placeholder="Phone" required name="phone"
          value={Data.phone}
          onChange={handleChange} />
        <textarea placeholder="Additional Message" rows="4" name="msg"
          value={Data.msg}
          onChange={handleChange}></textarea>
        <button type="submit">Contact</button>
      </form> 
     
    </section>
     {/* Display the Notification */}
     {notification.message && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={closeNotification}
                />
            )}
    </>
  )
}

export default Contact
