import React from 'react'
import flower from "../img/flowers.png"
import image1 from "../img/1.jpeg";
import image2 from "../img/2.jpeg";
import image3 from "../img/3.jpeg";
import image4 from "../img/4.jpeg";
import image5 from "../img/5.jpeg";
import image6 from "../img/6.png";
import image7 from "../img/7.jpeg";
import image8 from "../img/8.jpeg";
import image9 from "../img/9.jpeg";
import image10 from "../img/10.jpeg";
import image11 from "../img/11.jpeg";
import image12 from "../img/12.jpeg";
function Gallery() {
  
    const images = [image1, image2, image3, image4, image5, image6 , image7 ,image8,  image9,image10,image11,image12];
  return (
    <>
    <div>
     <div className='galleryhead'>
     <h1>Happily Ever After: Our Gallery of Joyful Couples</h1>
     <img src={flower} alt="logo" />
     </div>
      <div className="gallery">
      {images.map((image, index) => (
        <div className="gallery-item" key={index}>
          <img src={image} alt={`Gallery ${index + 1}`} />
        </div>
      ))}
    </div>
    </div>
   
    </>
  )
}

export default Gallery
