import React from 'react'
import Carousal from './Carousal'
import { Link } from 'react-router-dom'

function Practice() {
  return (
    <>
   
  <Carousal/>
    <section className="practice-areas">
    <h2>Our Legal Practice Areas</h2>
    <div className="area">
      <h3> <Link to="/spousevisa" className='navlink'>Couple Go Abroad on Visa by Spouse Visa</Link></h3>
      <p>Visa Options for Married Couples Planning to Go Abroad</p>
    </div>
    <div className="area">
      <h3> <Link to="/courtmarriage" className='navlink'>Court Marriage</Link></h3>
      <p>Court Marriage in India is a simple and legally recognized process where two individuals can marry without the need for a religious ceremony.</p>
    </div>
    <div className="area">
      <h3><Link to="/lovemarriage" className='navlink'>Love Marriage</Link></h3>
      <p>Love Marriage is a marriage in which the couple decides to marry based on love, affection, and personal choice, rather than traditional arranged marriage norms.
      </p>
    </div>
    <div className="area">
      <h3><Link to="/marriagebyprotection" className='navlink'>Court Marriage with Police Protection</Link></h3>
      <p>Understanding the Need for Police Protection in Court Marriages</p>
    </div>
    <div className="area">
      <h3><Link to="/marriagebygovt" className='navlink'>Marriage Registration for Government Docs</Link></h3>
      <p>Marriage Registration is a legal acknowledgment of marriage by the government, granting the couple a marriage certificate.</p>
    </div>
    <div className="area">
      <h3><Link to="/NRI" className='navlink'>NRI Marriage under Special Marriage Act</Link></h3>
      <p>Indian citizens residing abroad (Non-Resident Indians) may also marry under the Special Marriage Act, 1954, or choose a court marriage within India.</p>
    </div>
    <div className="area">
      <h3><Link to="/hindumarriage" className='navlink'>Hindu Marriage</Link></h3>
      <p>The Hindu Marriage Act, 1955, outlines the legal requirements for marriage between Hindus in India.</p>
    </div>
    <div className="area">
      <h3><Link to="/specialmarriage" className='navlink'>Special Marriage Act</Link></h3>
      <p>The Special Marriage Act, 1954 is a law enacted in India that allows individuals of different religions, castes, or communities to marry without having to convert or perform any religious ceremonies.</p>
    </div>
  </section>

  </>
  )
}

export default Practice
