import React from 'react'
import image from '../img/hero back.png'
import Strategy from './Strategy';
import FAQ from './FAQ';

function Govtdocsmarriageregister() {
 
  return (
    <div>
     <div className="family-law-service">
      <section className="intro-section">
        <br />
        <br />
        <h1>Couple Marriage Registration for Government Docs</h1>
        <p>
        Importance of Marriage Registration
        </p>
        <p>
        Marriage Registration is a legal acknowledgment of marriage by the government, granting the couple a marriage certificate. This certificate is essential for various purposes, including:
        </p>
        <li>Legal Proof of Marriage: Validates the marriage in the eyes of the law.</li>
        <li>Joint Accounts and Loans: Helps in opening joint bank accounts or applying for joint loans.</li>
        <img src={image} alt="Family" className="intro-image" />
        <li>Inheritance and Property Rights: Establishes legal rights for inheritance and property claims.</li>
       <li>Insurance and Pension Benefits: Enables claiming insurance and pension benefits as a legal spouse.</li>
      </section>

      <Strategy/>
      <FAQ/>
    </div>
    </div>
  )
}

export default Govtdocsmarriageregister
