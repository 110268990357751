import React from 'react'
const notificationStyles = {
    container: {
        padding: '10px 20px',
        borderRadius: '5px',
        color: 'white',
        zIndex: 999,
        display:'flex',
        maxWidth: '300px',
        marginLeft:"40%"
    },
    success: { backgroundColor: 'green' },
    error: { backgroundColor: 'red' },
    warning: { backgroundColor: 'orange' },
};
function Notification({ message, type, onClose }) {
    if (!message) return null;
  return (
    <div>
       <div
            style={{
                ...notificationStyles.container,
                ...notificationStyles[type],
            }}
        >
            <span>{message}</span>
            <button onClick={onClose} style={{ marginLeft: '10px', color: 'white', background: 'none', border: 'none', cursor: 'pointer' }}>
                ✖
            </button>
        </div>
    </div>
  )
}

export default Notification
