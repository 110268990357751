import React, { useState } from 'react'

function Chatbox({ isOpen, onClose }) {
    const qaData = [
        { id: 1, question: "What is your expertse?", answer: "We are experts in all legal matters" },
        { id: 2, question: "What are your hours of operation?", answer: "We are open from 9 AM to 6 PM, Monday to Friday." },
        { id: 3, question: "How can I contact support?", answer: "You can contact support via phone at+91 92665 58488" },

    ];

    const [selectedQA, setSelectedQA] = useState(null); // Holds the selected question and answer

    const handleQuestionClick = (qa) => {
        setSelectedQA(qa);
    };


    return (
        <div>
            {isOpen && (
                <div className="chat-box">
                    <div className="chat-box-header">
                        <h4>Chat</h4>

                    </div>
                    <div className="chat-box-content">
                        <h5>Common Questions</h5>
                        <div className="questions-list">
                            {qaData.map((qa) => (
                                <button
                                    key={qa.id}
                                    className="question-item"
                                    onClick={() => handleQuestionClick(qa)}
                                >
                                    {qa.question}
                                </button>
                            ))}
                        </div>
                        <div className="answer-section">
                            {selectedQA ? (
                                <>
                                    <div className="question-text">{selectedQA.question}</div>
                                    <div className="answer-text">{selectedQA.answer}</div>
                                </>
                            ) : (
                                <p>Select a question to see the answer.</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Chatbox
