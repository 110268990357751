import React from 'react'
import image from '../img/6.png'
import Strategy from './Strategy';
import FAQ from './FAQ';
function Specialmarriage() {
 
  return (
    <div>
      <div className="family-law-service">
        <section className="intro-section">
          <br />
          <br />
          <h1>Couple Special Marriage Act</h1>
          <p>
            The Special Marriage Act, 1954 is a law enacted in India that allows individuals of different religions, castes, or communities to marry without having to convert or perform any religious ceremonies. It provides a straightforward, legally recognized process for marriage, allowing for a civil ceremony in front of a marriage officer. This act also governs marriages between Indian citizens and foreigners and can apply to Indian citizens abroad.
          </p>

          <img src={image} alt="Family" className="intro-image" />
          <p>
          </p>
          <li>Age Requirements</li>
          <p>The bride must be at least 18 years old.</p>
          <p>The groom must be at least 21 years old.</p>
          <li>Mental Capacity</li>
          <p>Both parties should be capable of giving consent and not mentally unsound.</p>
          <li>Marital Status</li>
          <p>Both parties should be unmarried. If previously married, they must provide proof of divorce or death of their former spouse</p>
          <li>Prohibited Relationship</li>
          <p> The couple should not be related within the degrees of prohibited relationships as specified by the Act.</p>
        </section>

        <Strategy/>

        <section className="related-services">
          <h2>Our Related Services</h2>
          <div className="service-cards">
            <div className="service-card">
              <h3>Court Marriage with Police Protection</h3>
              <p>Conducted under the Special Marriage Act, 1954, this type of marriage allows individuals from different religions to marry without converting.
                This act requires a 30-day notice period, during which any objections can be raised.
                This is a civil marriage and does not require religious ceremonies.</p>
            </div>
            <div className="service-card">
              <h3>Inter-caste Court Marriage</h3>
              <p>Also governed by the Special Marriage Act, 1954, this type of marriage allows individuals from different castes to marry legally.
                The couple can marry through a civil process without adhering to caste-based customs or rituals.</p>
            </div>
          </div>
        </section>
<FAQ/>
        </div>
    </div>
  )
}

export default Specialmarriage
