import './App.css';
import { useState} from 'react';
import AboutUs from './component/AboutUs';
import Content from './component/Content';
import Footer from './component/Footer';
import Gallery from './component/Gallery';
import assist from './img/assist.png'
import { Route, Routes } from "react-router-dom"
import Services from './component/Services';
import Practice from './component/Practice';
import Contact from './component/Contact';
import Map from './component/Map';
import Couplegoabroadvisa from './SidebarComponents/Couplegoabroadvisa';
import Courtmarriage from './SidebarComponents/Courtmarriage';
import Govtdocsmarriageregister from './SidebarComponents/Govtdocsmarriageregister';
import Hindimarriage from './SidebarComponents/Hindimarriage';
import NRimarriage from './SidebarComponents/NRimarriage';
import Lovemarriage from './SidebarComponents/Lovemarriage';
import Specialmarriage from './SidebarComponents/Specialmarriage';
import Policeprotectioncourtmarriage from './SidebarComponents/Policeprotectioncourtmarriage';
import Tophead from './component/Tophead';
import ChatBox from './component/Chatbox';
import Model from './component/Model';
function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
    const toggleChatBox = () => {
        setIsChatOpen(!isChatOpen);
    };
  return (
    <>

     <Tophead/>
     <Model/> 
     <div style={{paddingTop:"10%"}}>
      <Routes >
        <Route path='/' element={<Content />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/services' element={<Services />} />
        <Route path='/workingarea' element={<Practice />} />
        <Route path='/contact' element={<Map />} />
        <Route path='/bookappoinment' element={<Map/>} />
        <Route path='/spousevisa' element={<Couplegoabroadvisa />} />
        <Route path='/courtmarriage' element={<Courtmarriage />} />
        <Route path='/marriagebygovt' element={<Govtdocsmarriageregister />} />
        <Route path='/hindumarriage' element={<Hindimarriage />} />
        <Route path='/NRI' element={<NRimarriage />} />
        <Route path='/lovemarriage' element={<Lovemarriage />} />
        <Route path='/specialmarriage' element={<Specialmarriage />} />
        <Route path='/marriagebyprotection' element={<Policeprotectioncourtmarriage />} />
      </Routes>
      </div>
      <Contact />
      <Footer />
      <div className="App">
            <button className="chat-toggle-button" onClick={toggleChatBox}>
               <img src={assist} alt="" className='assist' />
            </button>
            <ChatBox isOpen={isChatOpen} onClose={toggleChatBox} />
        </div>
      
    </>
  );
}




export default App;

