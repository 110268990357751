import React from 'react'

function Eligibilitycriteria() {
  return (
    <>
    <div className='eligible'>
      <h1>Eligibility Criteria for Court Marriages </h1>
      <br />
    <p>Anyone who wants to perform their court marriage must have to fulfill the eligibility criteria for their court marriage. There are certain condition anyone needs to fulfill before their marriage.</p>
   <div className='rulescreteria' >
   <ul>
    <li><b>Age criteria: </b> As per indian law , anyone who wants to perform their court marriage must have to fulfill the minimum age required for male & female partners, The minimun age of both the partners: Male - 21 years & Female - 18 years.</li>
    <li><b>Mutual Consent Of Partners: </b>Approval of both patners is very important. Both the partners wants to marry each other. They have taken this decision to perform the marriage without any pressure or force </li>
    <li><b>Non-Prohibited Relationship: </b> Certain Marriages are not allowed in different religions & customs. Some relationships are prohibited in different Indian Laws & Different Religions. Both the partners must not belong to these relationships before performing the marriages.</li>
    <li><b>No Active Relationships: </b> In Delhi, bigamy is not allowed. Both the partners must have a prior active relationship. In the case of prior relationships, either they got the divorce decree from the court or one of the spouses is not living. A Death Certificate or Divorce Dcree will be needed.</li>
   </ul>
    </div>
    </div>
    </>
  )
}

export default Eligibilitycriteria
