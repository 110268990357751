import React from 'react'
import image from '../img/14.jpeg'
import Strategy from './Strategy';
import FAQ from './FAQ';

function Hindimarriage() {
  
  return (
    <div>
      <div className="family-law-service">
      <section className="intro-section">
        <br />
        <br />
        <h1>Couple Hindu Marriage</h1>
        <p>
        The Hindu Marriage Act, 1955, outlines the legal requirements for marriage between Hindus in India. Here are the key provisions relevant for a Hindu court marriage:
        </p>
        <p>Eligibility Criteria</p>
        <p>
          <li>Age: The groom must be at least 21 years old, and the bride must be at least 18 years old.</li>
          <li>
        Mental Health: Both parties must be mentally sound to consent to the marriage, meaning neither party should suffer from any form of mental disorder that renders them unfit for marriage or for procreation of children.</li></p>
        <img src={image} alt="Family" className="intro-image" />
        <h1>Procedure for Court Marriage under the Hindu Marriage Act</h1>
        <p>
        Hindu Marriages typically follow religious customs and rituals. However, if a couple wishes to marry legally in a court without the religious ceremony, they must opt for a marriage under the Special Marriage Act, 1954. This act allows for court marriage irrespective of religion, and the procedure includes:
         </p>
         <h3>Legal Rights After Marriage</h3>
        <p>
        Under the Hindu Marriage Act, both spouses have equal rights and duties. The wife and husband have rights to mutual support, inheritance rights, and rights related to property, maintenance, and alimony. The Act also includes provisions for divorce, judicial separation, and annulment based on various grounds, such as cruelty, desertion, and adultery.
        </p>
      </section>

     <Strategy/>
     <FAQ/>
    </div>
    </div>
  )
}

export default Hindimarriage
