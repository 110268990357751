import React from 'react'
import image from '../img/1.jpeg'
import Strategy from './Strategy'
import FAQ from './FAQ'
function NRimarriage() {
  
  return (
    <div>
      <div className="family-law-service">
        <section className="intro-section">
          <br />
          <br />
          <h1>NRI Marriage Criteria and Rules</h1>
          <p>
            <li>Eligibility Requirements

Minimum Age: The minimum age for marriage is 21 for men and 18 for women, as per Indian laws (Hindu Marriage Act, Special Marriage Act).</li>
           <li>
           Marital Status: Both individuals must be single, divorced, or widowed at the time of marriage registration.
           </li>
           <li> Consent: Both parties must provide free and voluntary consent without any coercion.</li>
           <li> Residency Requirement: Under the Special Marriage Act, one of the individuals may need to have resided in India for at least 30 days prior to the registration application.</li>
          </p>
          <p>
            Indian citizens residing abroad (Non-Resident Indians) may also marry under the Special Marriage Act, 1954, or choose a court marriage within India.
          </p>
          <img src={image} alt="Family" className="intro-image" />
          <p>
            This process requires prior notice and possibly some additional documentation, depending on the country of residence.</p>

        </section>
        
        <section className="related-services">
          <h2>Our Related Services</h2>
          <div className="service-cards">
            <div className="service-card">
              <h3>Court Marriage with Police Protection</h3>
              <p>Conducted under the Special Marriage Act, 1954, this type of marriage allows individuals from different religions to marry without converting.
                This act requires a 30-day notice period, during which any objections can be raised.
                This is a civil marriage and does not require religious ceremonies.</p>
            </div>
            <div className="service-card">
              <h3>Inter-caste Court Marriage</h3>
              <p>Also governed by the Special Marriage Act, 1954, this type of marriage allows individuals from different castes to marry legally.
                The couple can marry through a civil process without adhering to caste-based customs or rituals.</p>
            </div>
          </div>
        </section>
<Strategy/>
<FAQ/>
       
      </div>
    </div>
  )
}

export default NRimarriage
